import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK re-introduces mortgages at 90% LTV</h1>
    <p>HSBC UK is expanding its mortgage lending by re-introducing 90% Loan to Value mortgages from Tuesday 12th January, the bank announced today.</p>
    <p>
      The decision, which will provide 2 year and 5 year fixed rate options up to 35 year terms, gives a welcome boost to first time buyers who typically utilise higher LTV mortgages. Mortgage rates will be announced on the 12th January.
      <br/>
      Michelle Andrews, HSBC UK’s Head of Buying a Home, said: “I am pleased and proud to announce that we will be re-introducing mortgages at up to 90% LTV. These mortgages build on our significant support for brokers and mortgage customers throughout 2020 and will be available across the board – for home purchases, first time buyers and to those remortgaging - all up to a maximum of 35 years.
    </p>
    <p>“The new lockdown will undoubtedly present challenges, but the experience of overcoming numerous difficulties during the original lockdown, for example making more use of automated valuations, will be invaluable. We are all seeking a return to normal, although for many it will feel like we may not see that for a while. With us returning to the higher LTV space, hopefully that is a little bit of welcome normality.”</p>
    <p>These 90% LTV mortgages will be available direct from HSBC UK and through its broker partners. Customers may be able to borrow up to £400,000.</p>
  </NewsArticle>
)

export default Article
